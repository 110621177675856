html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

  /*
  display: flex;
  flex-direction: column;
  */
  margin: 0px;
  min-height: 100vh;
}

/*
#__next {
  min-height: 100vh;
}
*/
.fs-header-and-main {
  display: flex;
  flex: 1 auto;
  flex-direction: column;
  min-height: 100vh;
  /*padding: 0px;*/
  /*align-items: center;*/
}

a {
  /*
  color: inherit;
  text-decoration: none;
  */
}

a {
  color: #6d2d9a;
  text-decoration: underline;
  text-decoration-color: #6d2d9a80;
  text-underline-offset: 3px;
}

a:focus,
a:active,
a:hover {
  background-color: #6d2d9a20;
  text-decoration-color: #000000;
}

* {
  box-sizing: border-box;
}

main {
  margin: 32px;
}

p,
li {
  line-height: 150%;
}

h1 {
  font-weight: 300;
}

h3 {
  font-weight: 500;
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
}

/* Hamburger menu ---------------------------------------------------------- */

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 24px;
  height: 20px;
  right: 32px;
  top: 22px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #ffffff;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  /* background: #a90000; */
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  /* FIXME: height/width is not respected */
  height: 132px;
  width: 32px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #ffffff;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #dddddd;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

.bm-item-list a {
  font-size: 20px;
  display: block;
  /*margin: 8px 0px;*/
  padding: 8px;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

/* ------------------------------------------------------------------------- */

@media (max-width: 600px) {
  main {
    margin: 32px 16px;
  }

  .bm-burger-button {
    right: 16px;
  }
}